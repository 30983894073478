.mention, .draftJsMentionPlugin__mention__29BEd, .mention:hover, .draftJsMentionPlugin__mention__29BEd:hover {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 2px;
  font-size: 14px;
  color: #fff;
  background: -webkit-gradient(linear,left top,left bottom,from(#007bff),to(#2b91ff));
  background: -webkit-linear-gradient(top, #007bff,#2b91ff);
  background: linear-gradient(180deg, #007bff, #2b91ff);
  z-index: 1000;
}

.draftJsMentionPlugin__mentionSuggestions__29BEd {
  height: 300px;
}
.mentionSuggestions, .draftJsMentionPlugin__mentionSuggestions__2DWjA {
  border: 1px solid #eee;
  margin-top: 0.4em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
          transform: scale(0);
  max-height: 200px;
  overflow-y: scroll;
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
  display: table-cell;
  vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
}

.mentionSuggestionsEntry {
  padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.mentionSuggestionsEntry:active {
  background-color: #cce7ff;
}

.mentionSuggestionsEntryFocused {
  composes: mentionSuggestionsEntry;
  background-color: #e6f3ff;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentionSuggestionsEntryText {
}

.mentionSuggestionsEntryTitle {
  font-size: 80%;
  color: #a7a7a7;
}

.mentionSuggestionsEntryAvatar {
  display: block;
  width: 25px;
  height: 25px;
  background-image: url('/images/variable-64_gray.png');
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 0;
}

.mentionSuggestionsEntryContainer:hover .mentionSuggestionsEntryAvatar {
  background-image: url('/images/variable-64_blue.png');
}

.entryContainer {
  position: absolute;
}
.entryContent {
  height: 200px;
  overflow-y: scroll;
}

.entryContainerLink {
  position: absolute;
  left:0px;
  bottom: 10px;
  z-index: 1000;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
  padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm:active {
  background-color: #007bff;
}

.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
  color: #000;
  background-color: #9bcbff;
}

.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
  margin-bottom: 0.2em;
}

.draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}
.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  border: 1px solid #eee;
  margin-top: 0.4em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
          transform: scale(0);
}
