.privacy_container {
  padding: 20px;
  background-color: #fff;
  font-size: 16px;
  color: #000;
  max-width: 1000px;
  margin: 0 auto;
}

.privacy_container h1 {
  margin-bottom: 10px;
}

.privacy_container h2 {
  margin-top: 40px;
  margin-bottom: 20px;
}
