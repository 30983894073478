.btn-margin {
  margin: 7px 3px;
}

.btn {
  font-size: .85rem !important;
}

.form-control {
  font-size: .8rem;
}

.app_content {
  width: 1025px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.app_carousel {
  height: 220px;
}

.app_marquee_container {
    width: 100%;
    height: 11em;
    margin: 0 auto;
    margin-top: 20px;
    overflow: hidden;
    background: #eee;
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
}

.app_marquee_content {
  height: 100%;
  overflow: hidden;
}

.marquee {
  position: relative;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 300;
  color: #25383C;

  /* Starting position */
 -moz-transform:translateY(90%);
 -webkit-transform:translateY(90%);
 transform:translateY(90%);

 /* Apply animation to this element */
 -moz-animation: marquee 30s linear infinite;
 -webkit-animation: marquee 30s linear infinite;
 animation: marquee 30s linear infinite;
}

.marquee:hover {
  animation-play-state: paused;
}

/* Move it (define the animation) */
@-moz-keyframes marquee {
 0%   { -moz-transform: translateY(11em); }
 100% { -moz-transform: translateY(-100%); }
}

@-webkit-keyframes marquee {
 0%   { -webkit-transform: translateY(11em); }
 100% { -webkit-transform: translateY(-100%); }
}

@keyframes marquee {
 0%   {
 -moz-transform: translateY(11em); /* Firefox bug fix */
 -webkit-transform: translateY(11em); /* Firefox bug fix */
 transform: translateY(11em);
 }
 100% {
 -moz-transform: translateY(-100%); /* Firefox bug fix */
 -webkit-transform: translateY(-100%); /* Firefox bug fix */
 transform: translateY(-100%);
 }
}

.review {
  height: 20px;
  overflow: hidden;
  margin-bottom: 5px;
}

.review:after {
  content: "";
  display: table;
  clear: both;
}

.review_title {
  float: left;
  height: 22px;
  margin-top: 0px;
  font-size: 17px;
}

.review_stars {
  float: left;
  width: 100px;
  margin-top: -10px;
  margin-left: -5px;
}

.review_author {
  font-weight: 400;
  color: #25383C;
  text-align: right;
  margin-right: 5px;
}


.social-container .links {
  margin-bottom: 40px;
  text-align: center
}

.social-container .links a {
  margin: 0 20px;
  color: #ccc;
  text-decoration: none;
  font-size: 34px;
  font-weight: bold;
  text-shadow: 0 2px 3px #fff
}

.social-container .links a:hover {
  color: #ed452a
}
