.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 8px;
  border-radius: 8px;
  box-shadow: none;
  background: #fefefe;
  margin-bottom: 5px;
}

.DraftEditor-root {
  position: relative;
  overflow: hidden;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 40px;
}

.public-DraftEditorPlaceholder-inner {
  color: #999;
  font-style: italic;
}

.editor.singleLine .public-DraftStyleDefault-block {
  display: flex;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  overflow-x: auto;
  white-space: pre;
}

.editor.singleLine .public-DraftStyleDefault-block::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    height: 0px;
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.editor.singleLine .public-DraftStyleDefault-block::-webkit-scrollbar-thumb {
    height: 0px;
    background: transparent;
}
