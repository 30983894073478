
html {
	height: 100%;
}
#root {
	height: 100%;
}

#authpage {
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -161.5px 0 0 -75.5px;
}

#drop-zone {
    border: 2px dashed #ccc;
}

.empty {
    background: #f8f9fa;
    border-radius: .1rem;
    color: #667189;
    padding: 3.2rem 1.6rem;
    text-align: center;
}

.btn-default { background: #fff; border: #ccc }

.toggle-on.btn-xs {
    padding-right: 27px;
    line-height: 12px;
}

.toggle-off.btn-xs {
    padding-right: 1px;
    line-height: 12px;
}

.icon.icon-3x {
    font-size: 4rem;
		font-weight: 500;
		font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif;
}

.empty .empty-subtitle, .empty .empty-title {
    margin: 2rem auto;
}

.empty-title {
	font-size: 2.0rem;
	font-weight: 500;
}

.hosting_header {
	font-size: 2.2rem;
	font-weight: 500;
	max-width: 1200px;
	margin: 0 auto;
	margin-left: 20px;
	color: #4681B4;
}

.hosting_subheader {
	font-size: 1.4rem;
	font-weight: 400;
	max-width: 1200px;
	margin: 0 auto;
	margin-left: 20px;
	margin-bottom: 20px;
}

.cw_container {
	height: 100%;
	min-height: 100%;
	background-color: #fff;
	max-width: 1200px;
	margin: 0 auto;
	padding-top: 2px;
	padding-bottom: 20px;
}

.cw_container2 {
	height: 100%;
	min-height: 100%;
	background-color: #fff;
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
}

.step {
    display: flex;
    display: -ms-flexbox;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    list-style: none;
    margin: .16rem 0;
    width: 100%;
		margin: 40px 0px 40px;
}

.step .step-item {
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    margin-top: 0;
    min-height: .8rem;
    position: relative;
    text-align: center;
}

.step .step-item a {
    color: #acb3c2;
    display: inline-block;
    padding: 20px 10px 0;
    text-decoration: none;
}

.step .step-item.active a::before {
    background: #fff;
    border: .16rem solid #5755d9;
		box-sizing: inherit;
}

.step .step-item.active~.step-item::before {
    background: #e7e9ed;
		box-sizing: inherit;
}

.step .step-item a::before {
    background: #5755d9;
    border: .08rem solid #fff;
    border-radius: 50%;
    content: "";
    display: block;
    height: .9rem;
    left: 50%;
    position: absolute;
    top: .24rem;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: .9rem;
    z-index: 1;
		box-sizing: inherit;
}

.step .step-item:not(:first-child)::before {
    background: #5755d9;
    content: "";
    height: 2px;
    left: -50%;
    position: absolute;
    top: 9px;
    width: 100%;
		box-sizing: inherit;
}

.step .step-item.active~.step-item a::before {
    background: #e7e9ed;
		box-sizing: inherit;
}

.paging {
    display: flex;
    display: -ms-flexbox;
    list-style: none;
    margin: .16rem 0;
    padding: .16rem 0;
}

.paging .page-item {
    margin: .16rem .04rem;
}

.paging .page-item a {
    border-radius: .08rem;
    color: #667189;
    display: inline-block;
    padding: .16rem .32rem;
    text-decoration: none;
}

.paging .page-item.page-next {
    text-align: right;
}

.paging .page-item.page-next, .paging .page-item.page-prev {
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
}

.page-item-title {
	font-size: 1.5rem;
	font-weight: 600;
	margin: 0;
}

.page-item-subtitle {
	opacity: .5;
}

.chip {
  align-items: center;
  background: #f0f1f4;
  border-radius: 4rem;
  color: #667189;
  display: inline-flex;
  display: -ms-inline-flexbox;
  -ms-flex-align: center;
  font-size: 90%;
  height: .9rem;
  line-height: .8rem;
  margin: .08rem;
  max-width: 100%;
  padding: .9rem .8rem;
  text-decoration: none;
  vertical-align: middle;
}

.chip .btn-clear {
    transform: scale(.75);
}

.btn.btn-clear {
    background: 0 0;
    border: 0;
    color: currentColor;
    height: .8rem;
    line-height: .6rem;
    margin-left: .16rem;
    margin-right: -2px;
    opacity: 1;
    padding: 0;
    text-decoration: none;
    width: .7rem;
}

.btn.btn-clear::before {
    content: "\2715";
}

.clipboardCopy {
	display: inline-block;
	cursor: pointer;
	margin-left: 5px;
	font-size: 1.0rem;
	background-color: #36608C;
	border-radius: 4px;
	color: #fff;
	width: 19px;
	height: 19px;
	padding: 0;
	padding-bottom: 4px;
	line-height: 20px;
	text-align: center;
}

.clipboardCopy2 {
	display: inline-block;
	cursor: pointer;
	font-size: 1.0rem;
	background-color: #36608C;
	border-radius: 4px;
	color: #fff;
	width: 19px;
	height: 19px;
	padding: 0;
	padding-bottom: 4px;
	line-height: 20px;
	text-align: center;
}

.conversion_header {
	font-size: 2.0rem;
	font-weight: 500;
}

.conv_row {
	margin-top: 20px;
}

.tile.tile-centered {
    align-items: center;
    -ms-flex-align: center;
}
.my-2 {
    margin-bottom: .32rem!important;
    margin-top: .32rem!important;
}
.tile {
    align-content: space-between;
    align-items: flex-start;
    display: flex;
    display: -ms-flexbox;
    -ms-flex-align: start;
    -ms-flex-line-pack: justify;
		border-bottom: 1px solid #ccc;
		padding-bottom: 5px;
}

.tile.tile-centered .tile-content {
    overflow: hidden;
}

.tile .tile-content:not(:last-child) {
    padding-right: .32rem;
}
.tile .tile-content:not(:first-child) {
    padding-left: .32rem;
}
.tile .tile-content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.tile .tile-action, .tile .tile-icon {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.btn.btn-link {
    background: 0 0;
    border-color: transparent;
    color: #5755d9;
}

.tile.tile-centered .tile-subtitle, .tile.tile-centered .tile-title {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tile .tile-subtitle, .tile .tile-title {
    line-height: 1.6rem;
}

.tile-subtitle {
	font-size: 1.2rem;
}
.text-gray {
    color: #acb3c2;
}

.hosting_announce {
	font-size: 1.0rem;
}

.hosting_announce > ul {
	list-style-type: disc;
	margin-left: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.dashboard_upload {
	position: absolute;
	right: 10px;
	top: 0px;
}

.dashboard_header {
	position: relative;
}

.dashboard_header > h1 {
	font-size: 1.7rem;
}

.upload_instructions {
	text-align: center;
	margin-top: 20px;
	font-size: 1.2rem;
	font-weight: 500;
}

.upload_spinner {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.upload_failure {
	color: red;
	font-size: 1.6rem;
}
