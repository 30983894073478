body{
	font-family: 'Poppins', sans-serif;
	font-size: 15px;
	color: #272727;
}
a:focus{
	text-decoration:none;
}
a:hover{
	text-decoration:none;
}


.home_container {
  position: absolute;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
}

/*menu-css*/

.limited-time {
	color: #000;
	font-weight: bold;
}

.main-nav:after {
  clear: both;
  content: "\00a0";
  display: block;
  height: 0;
  font: 0px/0 serif;
  overflow: hidden;
}

.nav-brand {
  float: left;
  margin: 0;
}

.nav-brand a {
  display: block;
  padding: 11px 11px 11px 0px;
  color: #555;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 17px;
  text-decoration: none;
}

#main-menu {
  clear: both;
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@media (min-width: 768px) {
  #main-menu {
    float: right;
    clear: none;
  }
}


/* Mobile menu toggle button */

.main-menu-btn {
  float: right;
  margin: 15px 10px;
  position: relative;
  display: inline-block;
  width: 29px;
  height: 29px;
  text-indent: 29px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


/* hamburger icon */

.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
  position: absolute;
  top: 50%;
  left: 2px;
  height: 2px;
  width: 24px;
  background: #fff;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.main-menu-btn-icon:before {
  content: '';
  top: -7px;
  left: 0;
}

.main-menu-btn-icon:after {
  content: '';
  top: 7px;
  left: 0;
}


/* x icon */

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
  height: 0;
  background: transparent;
}

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
  top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}


/* hide menu state checkbox (keep it visible to screen readers) */

#main-menu-state {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}


/* hide the menu in mobile view */

#main-menu-state:not(:checked) ~ #main-menu {
  display: none;
}

#main-menu-state:checked ~ #main-menu {
  display: block;
}

@media (min-width: 768px) {
  /* hide the button in desktop view */
  .main-menu-btn {
    position: absolute;
    top: -99999px;
  }
  /* always show the menu in desktop view */
  #main-menu-state:not(:checked) ~ #main-menu {
    display: block;
  }
}
ul#main-menu {
    margin-top: 25px;
}
li.last_menu{
	margin-left:20px !important;
}
li.last_menu a{
	background-color:#fff;
	color:#0095ea;
	text-align:center;
	height:45px;
	border-radius:50px;
	width:120px;
	box-shadow: 3px 4px 15px 0px rgba(84, 84, 84, 0.3);
	transition: all ease 0.5s 0s;
}
li.last_menu a:hover{
	background-color:#f28f20;
	color:#fff;
	opacity:1;
	transition: all ease 0.5s 0s;
}


.header-top{
	background-image: url("/images/banner-images.png");
	background-repeat: no-repeat;
	background-position: center top;
}
.navbar-main{
	width: 100%;
	display: inline-block;
}
.navbar.navbar-light{

	background-color: transparent !important;
	border-radius: 0 !important;
}
a.navbar-brand{
	width: 40%;
}
.navbar-collapse .navbar-nav .nav-item .nav-link{
	color: rgba(255, 255, 255, 0.9);
	font-family: "Poppins";
	padding-right: 37px;
}
.navbar-menu{
	margin-top: 12px;
}

.nav-item .nav-link button{
	background: #fff;
	color: #0095ea;
	border-radius: 50px;
	padding: 11px 31px;
	display: inline-block;
	border: 2px solid #fff;
	transition: all 0.5s ease 0s;
}
.nav-item .nav-link button:hover{
	background: #0095ea;
	color: #fff;
	border: 2px solid #fff;
}



.banner{
	width: 100%;
	display: inline-block;
}
.banner-text{
	padding-top:70px;
	display: inline-block;
}
.banner-text h4{
	font-size: 45px;
	font-weight: 600;
	color: #fff;
	line-height: 67px;
	text-shadow: 1px 2px 2px rgba(92, 92, 92, 0.7);
}
.banner-text p {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.5px;
	margin-bottom:40px;
}
.slider_btn {
    color: #fff;
    font-weight: 400;
    background: #f28f20;
    border-radius: 50px;
	border:none;
    padding: 12px 28px;
	font-size:15px;
    text-transform: uppercase;
	transition: all ease 0.5s 0s;
	box-shadow: 3px 4px 15px 0px rgba(83, 83, 83, 0.4);
}
.slider_btn:hover {
    color: #f28f20;
    background: #fff;
	text-decoration:none;
	transition: all ease 0.5s 0s;
}


.banner-icon{
	margin-top: 90px;
}





/************* echo dot **************************/



.echo {
    padding-bottom: 0px;
    /*background-image: url(/images/banner-bg.jpg);*/
    background-size: 100%;
    background-repeat: no-repeat;
}

.echo-text{
	margin-top: 85px;
}

.echo-title h4{
	font-size: 36px;
	color: #0095ea;
	font-weight: 600;
	letter-spacing:0.5px;
}

.echo-text ul {
    margin-top: 10px;
    padding: 0 0 0 20px;
    text-decoration: none;
    list-style: none;

}
.echo-text ul li {
    font-size: 15px;
    font-weight: 500;
    color: #646464;
    padding-top: 13px;
    position: relative;
    line-height: 25px;
}
.echo-text ul li:before {
    position: absolute;
    content: "";
    background: #0095ea;
    width: 6px;
    height: 6px;
    left: -19px;
    right: 0;
    bottom: 0;
    top: 21px;
    border-radius: 50px;
}




/******************** voice ****************************/



/*.voice{
	background-image: url("/images/voice-bg.jpg");
}*/

.voice-title h4{
	font-size: 36px;
	color: #0195ea;
	font-weight: 500;
	line-height: 54px;
}
.voice-title p{
	font-size: 15px;
	font-weight: 500;
	color: #646464;
	line-height: 27px;
	margin-top: 10px;
}

.voice-icon {
	margin-top: 100px;
}
.free_btn{
	background: #f28f20;
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	padding: 12px 25px;
	border-radius: 50px;
	border: none;
	text-transform: uppercase;
	margin-top: 30px;
	transition: all 0.5s ease 0s;
	cursor: pointer;
	box-shadow:3px 4px 15px 0px rgba(176, 176, 176, 0.7);
}
.free_btn:hover{
	background: #e7810f;
	color: #fff;
}
.free_btn_box{
	margin-top:40px;
}



.images-voice img{
	float: right;
}
.voice-title{
	margin-top: 140px;
}





/***************** easy ****************************/



.easy {
	background-image: url("/images/easy.png");
	background-repeat: no-repeat;
	background-position: center top;

}
.easy-text{
	text-align: center;
	padding: 245px 0;
}

.easy-heding h4{
	color: #fff;
	font-weight: 500;
	font-size: 36px;
	margin-top: 10px;
}
.easy-heding p {
    font-size: 15px;
    color: #fff;
    margin-top: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 25px;
}




/******************* testimonial ***************************/


.testimonial{
	padding: 50px 0;
}
.testimonial-heding{
	text-align: center;
}
.testimonial-heding h4{
	font-size: 36px;
	color: #0095ea;
	font-weight: 500;
}

.testimonial-heding p {
    font-size: 15px;
    color: #878787;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 25px;
}
.testimonial-section{
	position: relative;
	margin-top: 10px;
	padding:10px;
}
.testimonial .down {
    position: absolute;
    bottom: 72px;
    width: 40px !important;
    left: 50px;
    margin: auto;
}
.owl-nav{
	display:none;
}
/*.testimonial-section {
	position: relative;
}
.testimonial-section:before{
	content: "";
	color: #fff;
	background-image: url("../images/down.png");
	background-repeat: no-repeat;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	position: absolute;
}*/
.testimonial-comment{
	text-align: center;
	background: #fff;
	box-shadow: 0px 5px 15px 0px rgba(213, 213, 213, 0.5);
	padding: 45px 15px;
  min-height: 210px;
}

.testimonial-comment p{

	font-family: "Poppins";
	font-size: 15px;
	font-weight: 500;
	color: #576f8e;
	text-align: justify;
	line-height: 28px;

}


.client-name-section{
	margin-top: 30px;
}
.client-name-section img{
	margin-left: 10px;
	float: left;
}

.client-name{
	margin-left: 20px;
	float: left;
	margin-top: 0px;
}
.client-name h4{
	font-family: "Poppins";
	font-size: 18px;
	color: #454545;
	font-weight: 400;
}
.client-name p{
	font-family: "Poppins";
	font-size: 16px;
	color: #888888;
	font-weight: 500;
}







/******************** publish ***************************/



.publish {
	padding: 50px 0;

}

.publish-icon {
	text-align: center;
}
.publish-title{
	margin-top: 50px;
}
.publish-title h4 {
    font-size: 45px;
    line-height: 67px;
    font-weight: 600;
    color: #0095ea;
	margin-bottom:25px;
    letter-spacing: 0.5px;
}
.get-start {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    background: #f28f20;
    padding: 12px 38px;
    border-radius: 50px;
    margin-top: 10px;
    transition: all 0.5s ease 0s;
    cursor: pointer;
	border:none;
	text-transform:uppercase;
	box-shadow: 3px 4px 15px 0px rgba(176, 176, 176, 0.7);
}
.get-start:hover{
	background: #e7810f;
	color: #fff;
}




/******************* footer *****************************/



.footer{
	background-image: url("/images/footer-bg.png");
	padding: 59px 0px 39px 0px;
	background-position: center top;
}
.footer-menu{
	text-align: center;
}
.footer-menu ul{
	list-style: none;
	padding: 0;
}
.footer-menu ul li{
	float: left;
	padding-left: 40px;
}
.footer-menu ul li a{
	color: #fff;
	font-family: "Poppins";
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
	transition: all 0.52s ease 0s;
}
.footer-menu ul li a:hover{
	color: #f28f20;
}



.social{
	text-align: center;
	margin-top: 30px;
}
.social ul{
	list-style: none;
	padding: 0;
}
.social ul li{
	float: left;
	padding-left: 40px;
}

.footer-icon {
	text-align: center;
	margin-top: 30px;
}


.footer-icon h4{
	margin-top: 30px;
	font-size: 16px;
	color: #fff;
	font-weight: 400;
}





#footer {
  text-align: center;
  list-style: none;
}

#footer li {
  display: inline;
  margin: 2px;
  padding: 5px 20px;
}

#footer a {
  text-decoration: none;
  font-weight: 500;
  color: #fff;
  letter-spacing:0.5px;
}
#footer1 {
  text-align: center;
  list-style: none;
  margin-top:20px;
}

#footer1 li {
  display: inline;
  margin: 2px;
  padding: 5px 20px;
}

#footer1 a {
  text-decoration: none;
  font-weight: 500;
  color: #fff;
  letter-spacing:0.5px;
    color: #fff;
    font-family: "Poppins";
    font-size: 15px;
    background: #44b7f8;
    border-radius: 50px;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 2.6;
    margin: auto;
    -moz-box-shadow:    inset 0 0 10px #046aa4;
   -webkit-box-shadow: inset 0 0 10px #046aa4;
   box-shadow: inset 0 0 10px #046aa4;
	transition: all ease 0.5s 0s;
}
#footer1 a:hover{
	background-color: #fff;
	color: #0095ea;
	transition: all ease 0.5s 0s;
}

.dektop{
	display:block;
}
.phone{
	display:none;
}
ul#footer{
	padding:0px;
	margin:0px;
}
ul#footer1{
	padding:0px;
	margin: 0px;
	margin-top:30px;
}
#footer1 li {
    padding: 5px 5px;
}
.desktop_view{
	display:block;
}
.phone_view{
	display:none;
}
.icon_box{
margin-top:0px;
}
img.img-responsive.logoimg {
    margin: auto;
    margin-top: 40px;
}
.voice_detail h2{
	font-size:26px;
	color:#0095ea;
	line-height:30px;
}
.tab{
	display:none;
}
.tab1{
	display:block;
}
.video_play{
	margin:auto;
	cursor:pointer;
}

.home_close {
  color: black !important;
}
