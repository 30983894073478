.app-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: 60px;
    width: 230px;
    overflow: auto;
    z-index: 10;
    background-color: #e8f3f8;
    -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
    -webkit-transition: left 0.3s ease,
   width 0.3s ease;
    -o-transition: left 0.3s ease,
   width 0.3s ease;
    transition: left 0.3s ease,
   width 0.3s ease;
  }

  .app-menu {
    margin-bottom: 0;
    padding-bottom: 40px;
  }

  .app-menu__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 12px 15px;
    font-size: 1.08em;
    border-left: 3px solid transparent;
    -webkit-transition: border-left-color 0.3s ease,
   background-color 0.3s ease;
    -o-transition: border-left-color 0.3s ease,
   background-color 0.3s ease;
    transition: border-left-color 0.3s ease,
   background-color 0.3s ease;
    color: #fff;
  }

  .app-menu__item.active, .app-menu__item:hover, .app-menu__item:focus {
    background: #0d1214;
    border-left-color: #009688;
    text-decoration: none;
    color: #fff;
  }

  .app-menu__icon {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25px;
  }

  .app-menu__label {
    white-space: nowrap;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
.btn {
  color: white;
}
.app-content {
    min-height: calc(100vh - 50px);
    padding: 10px 20px;
    background-color: #E5E5E5;
    -webkit-transition: margin-left 0.3s ease;
    -o-transition: margin-left 0.3s ease;
    transition: margin-left 0.3s ease;
  }
.date-filter {
  padding: 0 20px;
}
.post-page{margin-top: 30px;}
.post-page .card:hover{transform: scale(1.02);}
.multi-select span .btn{ margin-bottom:0 !important}
.multi-select .btn-group{min-width:93%}
.multi-select .multiselect {min-width: 100%;text-align: left;}
.multi-select .btn .caret{float: right;margin-top: 8px;}
.m-b0{margin-bottom: 0 !important}
.date-select .react-datepicker-wrapper .form-control{background: url(/images/calendar.png);background-repeat: no-repeat;background-position: center right;}
.bdr-btm {
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
  @media (min-width: 768px) {
    .app-content {
      margin-left: 370px;
    }
  }

  @media (max-width: 767px) {
    .app-content {
      margin-top: 50px;
      min-width: 100%;
    }
  }

  @media (max-width: 480px) {
    .app-content {
      padding: 15px;
    }
  }

@media (min-width: 768px) {
    .app-content {
      margin-left: 370px;
    }
  }

  @media (max-width: 767px) {
    .app-content {
      margin-top: 50px;
      min-width: 100%;
    }
  }

  .app-sidebar {
    width: 370px;
  }

  .react-calendar {
    width: 370px !important;
  }
  .app-content {
    padding: 0px 20px !important;
  }
  .feed-alert{
    margin-top: 70px;
    font-size: 16px;
  }
.date-filter-section {
  position: relative;
  height: 100%;
  width: 100%;
}
  .feed-content {
    position: relative;
      margin-top: 0px;
    width: 100%;
    overflow-y: auto;
    height: 800px;
  }

.selected-post{
  border: 2px solid #28a745 !important;
}

.tile {
  position: relative;
}
.tile_bold {
  font-weight: bold;
  position:relative;
}

.dots {
  position: absolute;
  bottom: 2px;
  width: 100%;
  margin-left: -8px;
}
.dot {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-left: 2px;
}

.react-calendar {
  margin-top: 40px;
  padding: 5px;
  border-radius: 6px;
  -webkit-box-shadow: 10px 10px 41px -12px rgba(148,148,148,1);
-moz-box-shadow: 10px 10px 41px -12px rgba(148,148,148,1);
box-shadow: 10px 10px 41px -12px rgba(148,148,148,1);
}

.post_delete {
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 1000;
}
